.container-home {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-image: url("../assets/bg-home.jpg");
    flex-direction: column;
    overflow-y: auto;
    background-position: center;
}

.sub-container-home {
    width: 1100px;
    border-radius: 50px;
    border: solid;
    border-color: #feff00;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    padding: 50px;
}

.logo-sub-container {
    width: 100%;
    display: flex;
    justify-content: center;
    position: absolute;
    top: -80px;
}

.logo-sub-container > img {
    width: 200px;
    object-fit: contain;
}

.iframe-sub-container {
    width: 100%;
    height: 500px;
    padding-bottom: 20px;
}

.logo-sub-container > iframe {
    width: 100%;
    border-radius: 100px;
    height: 400px;
}

.infos-container-home {
    width: 100%;
    margin-top: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.button-home-container {
    padding: 10px 20px 10px 20px;
    width: 70%;
    animation: 3.4s cubic-bezier(0.2, 0.5, 0.9, 0.6) 3.4s infinite normal none
        running textura;
    background-image: linear-gradient(
        160deg,
        #ffe500,
        #ff6e00,
        #ffe500,
        #ff6e00
    );
    background-size: 400% 200%;
    transition: background 1.6s cubic-bezier(0.55, 0.1, 0.47, 0.94) 0s;
    font-family: "Sora", Sans-serif;
    fill: #000000;
    color: #000000;
    background-color: #fb2925;
    border-style: solid;
    border-width: 3px 3px 3px 3px;
    border-color: #ffb00057;
    border-radius: 72px 72px 72px 72px;
    font-weight: 800 !important;
}

.infos-container-home > p {
    text-align: center;
    color: #fff;
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 30px;
    font-size: 20px;
}

.container-button-home {
    width: 1100px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.button-acessar-app {
    font-family: "Sora", Sans-serif;
    font-weight: 700;
    fill: #000000;
    color: #000000;
    background-color: #69fb25;
    border-radius: 72px !important;
    padding: 20px 32px 20px 32px;
    border: none;
    margin-top: 10px;
    font-weight: 600;
    font-size: 18px;
    width: 100% !important;
}
.w-full {
    width: 100%;
}

@media (max-width: 1024px) {
    .container-button-home {
        width: 100% !important;
    }
    .sub-container-home {
        width: 100%;
        padding: 5px;
    }
    .container-home {
        justify-content: center;
        padding-top: 100px;
    }
    .button-home-container {
        margin-top: 40px;
    }
    .infos-container-home > p {
        font-size: 14px;
        font-weight: 500;
    }
    .logo-sub-container > iframe {
        border-radius: 100px;
    }
}
