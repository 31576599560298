.container-games {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url("../assets/bg-home.jpg");
    flex-direction: column;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    z-index: 1000;
}

.texts-centers-games {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.background-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
    width: 100%;
    justify-content: center;
    display: flex;
}

.sub-container-games {
    width: 480px;
    height: 100%;
    border-radius: 20px;
    padding: 10px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    padding: 5px;
    margin-bottom: 100px;
    overflow-y: auto;
    padding-bottom: 60px;
}

.logo-sub-container-games {
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo-sub-container-games > img {
    height: 140px;
}

.color-green {
    color: #00ff00;
    font-weight: 700;
}

.games-title {
    font-size: 32px;
    color: #fff;
    text-align: center;
    font-weight: 500;
    margin: 0;
}

.sub-title-red-games {
    text-align: center;
    font-size: 32px;
    color: #ff0000;
    margin: 0;
    margin-bottom: 20px;
}

.title-sub-games {
    text-align: center;
    color: white;
    font-size: 17px;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.span-green {
    color: #00ff00;
}

.itens-jogos-games {
    width: 100%;
    display: grid;
    grid-template-columns: 6fr 6fr;
    gap: 15px;
    margin-top: 20px;
}

.itens-games-container {
    width: 100%;
    display: flex !important;
    flex-direction: column !important;
}

.img-jogos-screan {
    /* width: 100%; */
    border-radius: 20px;
}

.img-jogos-screan > img {
    border-radius: 10px;
    object-fit: contain;
    /* height: 100%; */
}

.progress {
    width: 100%;
    height: 30px;
    background-color: #f0f0f0;
    border-radius: 5px;
    position: relative;
    margin-top: 10px;
}

.progress-bar {
    height: 30px;
    background-color: black;
    border-radius: 5px;
    position: relative;
    overflow: hidden;
}

/* INICIO Código para animação */
.progress-bar {
    position: inherit;
    background: #fefbe3;
    background-color: -webkit-linear-gradient(
        -45deg,
        transparent 25%,
        #fefbe370 25%,
        #fefbe370 50%,
        transparent 50%,
        transparent 75%,
        #fefbe370 75%
    );
    background: -moz-linear-gradient(
        -45deg,
        transparent 25%,
        #fefbe370 25%,
        #fefbe370 50%,
        transparent 50%,
        transparent 75%,
        #fefbe370 75%
    );
    background: -o-linear-gradient(
        -45deg,
        transparent 25%,
        #fefbe370 25%,
        #fefbe370 50%,
        transparent 50%,
        transparent 75%,
        #fefbe370 75%
    );
    background: linear-gradient(
        -45deg,
        transparent 25%,
        #fefbe370 25%,
        #fefbe370 50%,
        transparent 50%,
        transparent 75%,
        #fefbe370 75%
    );
    background-size: 27px 27px;
    -webkit-animation: barberpole 1.5s infinite linear;
    animation: barberpole 5s infinite linear;
}

@keyframes barberpole {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% 100%;
    }
}

/* FIM Código para animação */
.progress-bar:before {
    content: "";
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #2196f3;
    transition: width 0.3s ease-in-out;
}

.progress-bar.red:before {
    background-color: #f44336;
}

.progress-bar.yellow:before {
    background-color: #ffeb3b;
}

.progress-bar.green:before {
    background-color: #4caf50;
}

.progress-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 14px;
    color: black;
    font-weight: bold;
}

.progress-bar.red {
    background-color: #f0271b;
}

.progress-bar.yellow {
    background-color: #f0d926;
}

.progress-bar.green {
    background-color: #37ef68;
}

@media (max-width: 600px) {
    .sub-container-games {
        width: 100%;
    }
}
