.container-google-play {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.sub-container-google-play {
    width: 500px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header-google-play {
    width: 100%;
    height: 64px;
}

.menu-google-play {
    width: 100%;
    height: 74px;
}

.container-google-play-section {
    width: 100%;
    height: calc(100% - 138px);
    overflow-y: auto;
    background: #fff;
}

.header-google-play img,
.menu-google-play img,
.container-google-play-section img {
    width: 100%;
    height: auto;
}

.carousel {
    overflow: hidden;
    width: 100%;
    position: relative;
}

.carousel-track {
    display: flex;
    width: calc(100%); /* Ajustado para acomodar todas as 4 imagens */
    animation: scroll 20s linear infinite; /* Ajuste o tempo conforme necessário */
}

.carousel-item {
    flex: 0 0 50%; /* Cada imagem ocupa 25% da largura total */
}

.carousel img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@keyframes scroll {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100%);
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal-content {
    background: white;
    padding: 10px;
    border-radius: 8px;
    width: 480px;
    max-width: 90%;
    text-align: center;
    position: relative;
    color: #458461;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 20px;
    cursor: pointer;
}

.button-gradient-install-google-play {
    font-family: "Saira", Sans-serif;
    font-weight: 600;
    background-color: #458461;
    border-radius: 10px 10px 10px 10px;
    padding: 20px 70px 20px 70px;
    color: #fff;
    outline: none;
    border: none;
    text-decoration: inherit;
    font-size: 16px;
}

@media (max-width: 1024px) {
    .sub-container-google-play {
        width: 100%;
    }
    .modal-content {
        width: 100%;
    }
}
