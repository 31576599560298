.container-games-slots {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-image: linear-gradient(0deg, #774600 0%, #000000 100%);
}

.background-overlay-games-slots {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    background-image: url("../assets/games/bg-games.webp");
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow-y: auto;
}

.games-center-games-slots {
    width: 100%;
    display: grid;
    height: 100%;
    grid-template-columns: 6fr 6fr;
    padding: 10px;
    gap: 10px;
}

.games-slots-r-side {
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0px 0px 10px 0px;
    flex-direction: column;
}

.card-games-slots {
    width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 20px;
    z-index: 0;
    border-radius: 20px;
    position: relative;
}
.card-games-slots-form {
    width: 100%;
    margin-bottom: 10px;
}
.card-games-slots-form-2 {
    width: 70%;
    margin-bottom: 10px;
}
.games-slots-r-side-absolute {
    width: 500px;
}

.games-slots-r-side-absolute > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-card-games-slots {
    width: 100%;
    height: 70px;
    background-color: #000000b5;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    border-bottom: 4px #fff solid;
    gap: 10px;
}

.content-card-games-slots > img {
    height: 50px;
    cursor: pointer;
}

.title-games-slots {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 15px;
}
.title-games-slots > h1 {
    font-weight: 800;
    font-size: 20px;
    margin: 0;
}
.assertividade-text {
    font-size: 15px !important;
}

.green-games-slots {
    color: #00ff55;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-shadow: 0px 0px 11px rgba(36.65624999999996, 255, 0, 0.84);
    font-size: 25px !important;
}

.green-yellow {
    color: #ffcb00;
    font-family: "Poppins", Sans-serif;
    font-size: 30px;
    font-weight: 700;
    text-shadow: 0px 0px 11px rgba(255, 233.86548913043478, 0, 0.81);
    font-size: 25px !important;
}

.circle-games-slots {
    width: 45px;
    height: 40px;
    border-radius: 100px;
    background-color: #ffb700;
    display: flex;
    justify-content: center;
    align-items: center;
}

.button-green-games-slots {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 4px;

    font-family: "Poppins", Sans-serif;
    font-weight: 700;
    background-color: transparent;
    background-image: linear-gradient(204deg, #00d11d 0%, #1c8300 100%);
    border-style: solid;
    border-width: 0px 0px 4px 0px;
    border-color: #00ff48;

    font-size: 20px;
    font-weight: 800;
    color: #fff;

    cursor: pointer;
}

.contet-games-slots-infos {
    margin-top: 10px;
}

.contet-games-slots-infos > p {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 5px;
}

.contet-games-slots-infos > p:nth-child(2) {
    margin-top: 5px;
}

.games-slots-l-side {
    display: grid;
    width: 500px;
    height: 900px !important;
}

.iframe-sub-container-games-slots {
    width: 100%;
    height: 900px !important;
}

.content-fim {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 10px;
}

.content-fim > h1 {
    color: #6ec1e4;
    font-size: 55px;
    font-weight: 800;
}

.button-green-games-slots-disabled {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    outline: none;
    border: none;
    border-radius: 4px;

    font-family: "Poppins", Sans-serif;
    font-weight: 700;
    background-color: #61ce70;
    border-style: solid;
    border-width: 0px 0px 4px 0px;
    border-color: #00ff48;

    font-size: 20px;
    font-weight: 800;
    color: #fff;

    cursor: not-allowed;
}

.button-gradient-install {
    padding: 10px 30px;
    background-image: linear-gradient(190deg, #fbe820 0%, #ce5a14 100%);
    border: none;
    outline: none;
    cursor: pointer;
    font-size: 17px;
    border-radius: 4px;
    font-weight: 600;
    color: #fff;
}

.gradient-yellow {
    background-image: linear-gradient(190deg, #fbe820 0%, #ce5a14 100%);
}

@media (max-width: 600px) {
    .card-games-slots {
        width: 100%;
    }
    .games-slots-r-side-absolute {
        width: 100%;
        top: -282px;
    }
    .games-center-games-slots {
        flex-direction: column;
        display: flex;
        margin-top: 220px;
        gap: 0px;
    }
    .games-slots-l-side {
        width: 100%;
    }
}
