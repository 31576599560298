@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Zain:wght@200;300;400;700;800;900&display=swap");

html,
body {
    margin: 0 !important;
    padding: 0 !important;
    height: 100%;
    width: 100%;
    font-family: "Kanit", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #000; /* Ajuste conforme necessário */
    color: #fff;
    scroll-behavior: smooth;
    overflow-x: hidden; /* Previne scroll horizontal */
}

/* Reset básico para todos os elementos */
* {
    box-sizing: border-box;
    -webkit-touch-callout: none; /* Safari */
    -webkit-user-select: none; /* Chrome, Safari e Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    font-family: "Noto Sans", sans-serif !important;
}

body::-webkit-scrollbar {
    display: none; /* Oculta a scrollbar no Webkit (Chrome, Safari, etc.) */
}

a {
    text-decoration: none;
    color: inherit; /* Remove o sublinhado e usa a cor atual do texto */
}

img {
    max-width: 100%;
    height: auto; /* Garantir que as imagens sejam responsivas */
    display: block; /* Remove o espaço em branco abaixo das imagens */
}

::-webkit-scrollbar {
    width: 0; /* Oculta a barra de rolagem vertical */
    height: 0; /* Oculta a barra de rolagem horizontal */
}

::-webkit-scrollbar-thumb {
    background: transparent; /* Torna o "polegar" da barra de rolagem invisível */
}

p {
    margin: 0;
    padding: 0;
}

h1 {
    margin: 0;
    padding: 0;
}
h3 {
    margin: 0;
    padding: 0;
}

.button-pwa {
    width: 120px;
    height: 40px;
    border: none;
    background-color: #61ce70;
    color: #fff;
    font-size: 20px;
    font-weight: 700;
    cursor: pointer;
    border-radius: 4px;
}
